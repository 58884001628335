import React from "react";
import { Link } from "gatsby";
import { PageComponentProps } from "../../types/types";
import Layout from "../parts/Layout";

import * as pageStyles from "../global-styles/page.module.css";

export const AboutSheetMusic = (props: PageComponentProps) => {
  const { clairnoteName, urlDir } = props;
  const title = "About Sheet Music";

  const description =
    "All about our collection of sheet music in " +
    `${clairnoteName} music notation. Free to download, print, copy, modify, ` +
    "perform, etc. Thanks to the Mutopia Project, the Session, and LilyPond.";

  return (
    <Layout title={title} description={description} {...props}>
      <article>
        <h1 className={`title is-3 ${pageStyles.pageTitle}`}>{title}</h1>
        <p>
          We offer {clairnoteName} sheet music that is free to download, print,
          copy, modify, perform, record, etc. Almost all of these works were
          automatically converted from traditional music notation into{" "}
          {clairnoteName} using{" "}
          <a href="http://clairnote.org/software/">LilyPond</a>. Some of the
          LilyPond source files are from the{" "}
          <a
            href="http://www.mutopiaproject.org/"
            target="_blank"
            rel="external noopener noreferrer"
          >
            Mutopia Project
          </a>
          . Others have been converted to LilyPond format from ABC files from{" "}
          <a
            href="https://thesession.org/"
            target="_blank"
            rel="external noopener noreferrer"
          >
            the Session
          </a>
          . Others have been contributed by users. The ".ly" files have been
          modified slightly to achieve {clairnoteName} output. The pieces have
          links to their sources where you can find the original version in
          traditional music notation (where applicable).
        </p>
        <h2 className="title is-5">Searching and Filtering</h2>
        <p>
          Searches are "logical and" style and return results that match{" "}
          <em>all</em> of the search terms. You can also filter the results by
          collection, and for some collections you can filter the results
          further. For example, the Mutopia Project collection can be filtered
          by instrument, style, and composer. All these filters are "logical
          and" style, and filter out results that do not satisfy <em>all</em> of
          the filters.
        </p>
        <h2 className="title is-5">Browsing</h2>
        <p>
          To browse, leave the search box empty and optionally use one or more
          filters. For the Mutopia Project collection the works will be listed
          alphabetically by composer's last name, then by opus, then by title.
          For the Session collection they are listed alphabetically by title.
        </p>
        <h2 className="title is-5">Licensing</h2>
        <p>
          All of the files may be freely downloaded, printed, copied,
          distributed, modified, performed, and recorded, just like the original
          files. For example, see the{" "}
          <a
            href="http://www.mutopiaproject.org/legal.html"
            target="_blank"
            rel="external noopener noreferrer"
          >
            Mutopia Project license page
          </a>{" "}
          and the links to the license for each Mutopia Project piece.
        </p>

        <h2 className="title is-5">Thanks</h2>
        <p>
          This library would not be possible without LilyPond, the Mutopia
          Project, and the Session — many thanks to everyone who contributes to
          them! Thanks also to{" "}
          <a
            href="http://lunrjs.com/"
            target="_blank"
            rel="external noopener noreferrer"
          >
            lunr.js
          </a>{" "}
          for the search functionality.
        </p>
        <h2 className="title is-5">Disclaimer</h2>
        <p>
          Some of these files are works in progress and their quality may vary.
          Many have not been visually checked or edited for any potential layout
          problems after their automated conversion to {clairnoteName}. (For
          example, manual tweaks and spacing adjustments for traditional
          notation that are not needed for {clairnoteName}, or that were only
          needed for earlier versions of LilyPond.) Some settings of works from
          the Session are not included because of errors when converting them to{" "}
          {clairnoteName}. Please feel free to{" "}
          <Link to={urlDir + "contact/"}>contact us</Link> to report anything
          that needs work.
        </p>
      </article>
    </Layout>
  );
};
